// React
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// FutureLab
import { RootState } from '../../reducers';
import { getChatRoomItems, seenChatRoomItems } from '../../services/chatService';
import { SendChatMessage } from '../../components/chat/SendChatMessage';
import { useSocket } from '../../hooks/useSocket';
import { chatItemInFocus } from '../../actions/chatItemInFocusActions';
import { ChatParticipantsInterface } from './ChatListItemsMobile';
import { ChatRoomMessage } from './chatRoom/ChatRoomMessage';
import { ChatRoomMessageDetails } from './chatRoom/ChatRoomMessageDetails';
import { ScrollToBottom } from './chatRoom/ScrollToBottom';
import { getWindowSize, windowSizeType } from '../../utilities/WindowResize';
import { ChatRoomAttachmentFile } from './chatRoom/ChatRoomAttachmentFile';
import { updateChatList } from '../../utilities/Chat';

// UI
import { FlexboxGrid, Col } from 'rsuite';

// Redux
import { store } from '../../ReduxRoot';

// 3rd Party
import Cookies from 'js-cookie';
import _ from 'lodash';
import moment from 'moment';

export interface ChatRoomItemsInterface {
	pinned: PinnedMessageInterface[];
	attachments: any[];
	chatId: string;
	createdAt: string;
	id: number;
	message: string;
	participantId: number;
	participant?: number;
	replyTo: object;
	seenBy: number[];
}

export interface DeleteMessagePayloadInterface {
	chatId: string;
	messageId: string;
}

export interface FileAttachmentInterface {
	blobFile: BlobFileProps;
	name: string;
	fileKey: string;
	status: string;
}

interface BlobFileProps {
	lastModified: Date;
	lastModifiedDate: Date;
	name: string;
	size: number;
	type: string;
	webkitRelativePath: string;
}

export interface PinnedMessageInterface {
	chatId: string;
	chatMessageId: ChatRoomItemsInterface;
	id: number;
	participantId: number;
}

export function ChatRoom() {
	// useState initializers
	const [scrollToBottomNewMessage, setScrollToBottomNewMessage] = useState(false);
	const [initialLoadData, setInitialLoadData] = useState(false);
	const [callingApi, setCallingApi] = useState(false);
	const [lastSeenMessagedIds, setLastSeenMessagedIds] = useState<number[]>([]);
	const [lastSeenMessagedIdsSent, setLastSeenMessagedIdsSent] = useState<number[]>([]);
	// TODO find out whether this use state can be made into a fixed const instead to avoid any typescript issues
	const [apiLimit, setApiLimit] = useState(25);
	const [apiOffset, setApiOffset] = useState(0);
	const [chatRoomItems, setChatRoomItems] = useState<ChatRoomItemsInterface[]>([]);
	const [newSeenMessages, setNewSeenMessages] = useState<ChatRoomItemsInterface[]>([]);
	const [newMessage, setNewMessage] = useState<any>({});
	const [oldMessage, setOldMessage] = useState<any>({});
	const [loggedInUserParticipantId, setLoggedInUserParticipantId] = useState<ChatParticipantsInterface>();
	const [reachedEndOfMessages, setReachedEndOfMessages] = useState(false);
	const [deletedMessages, setDeletedMessages] = useState<DeleteMessagePayloadInterface>();
	const [files, setFiles] = useState<FileAttachmentInterface[]>([]);

	// useSelectors initializers
	const chatInFocus = useSelector((state: RootState) => state.chatInFocus);
	const loggedInUserDetails = useSelector((state: RootState) => state.loggedInUserDetails);
	const pinnedMessageInfocus = useSelector((state: RootState) => state.pinnedMessageInfocus);
	const chatListsItems = useSelector((state: RootState) => state.chatList);

	// Socket setup
	const socket = useSocket();
	const messagesEndRef = useRef<HTMLDivElement>(null); // html reference to scroll to the bottom of the page
	// html reference for binding the screen to the scrolling being triggered by the user
	const messagesListRef = useRef<HTMLDivElement>(null);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (pinnedMessageInfocus) {
			scrollToPInnedMessage();
		}
	}, [pinnedMessageInfocus]);

	const scrollToPInnedMessage = () => {
		const targetMessage = document.getElementsByClassName(`${pinnedMessageInfocus.chatMessageId.id}`);
		targetMessage[0]?.classList.add('highlighted-chat-room-element');
		setTimeout(() => {
			targetMessage[0]?.classList.remove('highlighted-chat-room-element');
		}, 6000);
		targetMessage[0]?.scrollIntoView({ behavior: 'auto' });
	};

	// initialize event subscription for the scrolling when the component is loaded for the first time
	useEffect(() => {
		const handleScroll = () => {
			if (messagesListRef?.current) {
				const { scrollTop } = messagesListRef?.current;
				const reachedTop = scrollTop === 0;
				if (reachedTop && !callingApi && !reachedEndOfMessages) {
					setCallingApi(true);
				}
			}
		};
		const debounceScrollEvents = _.debounce(handleScroll, 400);
		if (messagesListRef && messagesListRef?.current) {
			messagesListRef?.current?.addEventListener('scroll', debounceScrollEvents, { passive: true });
			return () => {
				if (messagesListRef && messagesListRef?.current) {
					messagesListRef?.current?.removeEventListener('scroll', debounceScrollEvents);
				}
			};
		}
	}, []);

	// Hooks that subscribes to the scrolling to the top event to trigger the api offset to trigger to api call
	useEffect(() => {
		if (!reachedEndOfMessages && callingApi && initialLoadData) {
			setApiOffset(apiOffset + 25);
		}
	}, [callingApi]);

	// hooks for subscribing to the offset of the messages to show that the new api
	// with the updated value of the offset needs to be called
	useEffect(() => {
		if (chatInFocus?.id || chatInFocus?._id) {
			if (apiOffset > 0) {
				getChatRoomItems({ chatId: chatInFocus.id || chatInFocus._id }, apiOffset, apiLimit)
					.then((res) => {
						setOldMessage(res?.data?.data?.reverse());
						if (res?.data?.data?.length === 0) {
							setReachedEndOfMessages(true);
							setApiOffset(0);
						}
						setCallingApi(false);
					})
					.catch((err) => {
						console.warn('Error:', err);
					});
			}
		} else {
			navigate('chat');
		}
	}, [apiOffset]);

	// hooks for the chat in focus to call the api for fethcing the initial array or
	// messages on the first load without the scrolling up to the top of the screen
	// and also updating the seen status on the db by calling the seen api and sending the ids of the chat item ids
	useEffect(() => {
		setChatRoomItems([]); // This simply to clear the previous chat room message to prevent a flicker of chat message

		// if the page is reloaded and the chat in focus doesnt exist then we should navigate the user back
		// to the chat page on the mobile mode to avoid the console errors and warnings
		setReachedEndOfMessages(false);
		setApiOffset(0);
		if (chatInFocus?.id || chatInFocus?._id) {
			getChatRoomItems(
				{
					chatId: chatInFocus.id || chatInFocus._id,
					pinnedMessageInfocus: pinnedMessageInfocus?.chatMessageId?.id,
				},
				apiOffset,
				apiLimit
			)
				.then((res) => {
					setChatRoomItems(res?.data?.data?.reverse());
					// scroll to the pinned messag if the navigation to the chatroom is from the pinned chat messages menu after clicking on
					// any pinned message, otherwise target the messageEndRef in order to scroll to the bottom once all the messages are loaded
					// and there is a scroll bar in the screen
					if (pinnedMessageInfocus) {
						scrollToPInnedMessage();
						setReachedEndOfMessages(true);
					} else {
						messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
					}
					setInitialLoadData(true);
					setCallingApi(false);
				})
				.catch((err) => {
					console.warn('Error:', err);
				});
			setLoggedInUserParticipantId(
				chatInFocus?.participants?.find((participant) => participant.userId === loggedInUserDetails.id)
			);
		} else {
			navigate('chat');
		}
	}, [chatInFocus]);

	const updateSeenStatus = (
		updatesArrayItems: ChatRoomItemsInterface[],
		chatRoomItemsCurrentForm: ChatRoomItemsInterface[]
	) => {
		let newArr = _.cloneDeep(chatRoomItemsCurrentForm);
		updatesArrayItems.map((item) => {
			const indexFound = newArr.findIndex((ele) => ele.id === item.id);
			newArr[indexFound] = item;
		});
		setChatRoomItems(newArr);
	};

	// hooks for updating the chat room items (the array of the data being rendered onto the screen)
	useEffect(() => {
		if (chatInFocus?.id || chatInFocus?._id) {
			const seenMessageIds = chatRoomItems.map((chats: ChatRoomItemsInterface) => chats.id);
			setLastSeenMessagedIds(seenMessageIds);
		} else {
			navigate('chat');
		}
	}, [chatRoomItems]);

	useEffect(() => {
		if (chatInFocus?.id || chatInFocus?._id) {
			// TODO put the comment for the logic for api call optimization for delete message event emittion
			if (
				!_.isEqual(lastSeenMessagedIdsSent, lastSeenMessagedIds) &&
				lastSeenMessagedIds >= lastSeenMessagedIdsSent
			) {
				seenChatRoomItems({
					chatId: chatInFocus.id || chatInFocus._id,
					messageIds: lastSeenMessagedIds,
				}).then((res) => {
					setLastSeenMessagedIdsSent(lastSeenMessagedIds);
					if (res.data.data.length > 0) {
						updateSeenStatus(res.data.data, chatRoomItems);
					}
				});
			}
		} else {
			navigate('chat');
		}
	}, [lastSeenMessagedIds]);

	// initialize event bindings on the screen for the chat room functionailty to be initialised
	useEffect(() => {
		socket.connect();
		socket.emit('INIT', { token: Cookies.get('token') });
		socket.on('NEW_MESSAGE', newMessageListener);
		socket.on('NEW_SEEN_MESSAGES', newSeenMessageListener);
		socket.on('DELETE_MESSAGE', deleteMessageListener);
		socket.on('DELETE_ALL_MESSAGES', deleteAllMessagesListener);

		return () => {
			disconnectSocket();
		};
	}, []);

	useEffect(() => {
		let newArr = _.cloneDeep(chatRoomItems);
		const filterNonDeletedMessage = newArr.filter(
			(chatRoomItem) => _.toString(chatRoomItem.id) !== deletedMessages?.messageId
		);
		setChatRoomItems(filterNonDeletedMessage);
	}, [deletedMessages]);

	const newMessageListener = (payload: any) => {
		setNewMessage(payload);
	};

	useEffect(() => {
		updateChatList(chatListsItems, newMessage, chatInFocus, loggedInUserDetails, dispatch);
	}, [newMessage]);

	const deleteMessageListener = (payload: DeleteMessagePayloadInterface) => {
		setDeletedMessages(payload);
	};

	const deleteAllMessagesListener = (payload: any) => {
		if (payload?.chatId === chatInFocus._id || payload?.chatId === chatInFocus.id) {
			setChatRoomItems([]);
		}
	};

	const newSeenMessageListener = (payload: any) => {
		if (payload.length > 0) {
			setNewSeenMessages(payload);
		}
	};

	// hooks for the the messages being viewed by the users on the screen to update the status of the double ticks
	useEffect(() => {
		let newArr = _.cloneDeep(chatRoomItems);
		newSeenMessages.map((item) => {
			item.participantId = item['participant'] || item.participantId; // TODO fix from BE side
			const indexFound = newArr.findIndex((ele) => ele.id === item.id);
			newArr[indexFound] = item;
		});
		setChatRoomItems(newArr);
	}, [newSeenMessages]);

	const disconnectSocket = () => {
		if (socket) {
			socket.disconnect();
			socket.close();
		}
		socket.off('NEW_MESSAGE', newMessageListener);
		socket.off('NEW_SEEN_MESSAGES', newSeenMessageListener);
		socket.off('DELETE_MESSAGE', deleteMessageListener);
		socket.off('DELETE_ALL_MESSAGES', deleteAllMessagesListener);
	};

	const sendChatClicked = () => {
		seenChatRoomItems({
			chatId: chatInFocus.id || chatInFocus._id,
			messageIds: chatRoomItems.map((chats: ChatRoomItemsInterface) => chats.id),
		}).then((res) => {
			if (res.data.data.length > 0) {
				updateSeenStatus(res.data.data, chatRoomItems);
			}
		});
	};

	// dismount event listerners when the message is being dismounted
	useEffect(() => {
		return () => {
			store.dispatch(chatItemInFocus(null));
			setChatRoomItems([]);
			setNewMessage({});
		};
	}, []);

	// Hooks connected to the sockets for subscribing to the new messages being emittmed from the backend
	useEffect(() => {
		if (!_.isEmpty(newMessage)) {
			if (newMessage.chatId === chatInFocus.id || newMessage.chatId === chatInFocus._id) {
				setChatRoomItems([...chatRoomItems, newMessage]);
				// do not scroll to bottom if the user has scrolled up and new message is recieved
				if (messagesListRef?.current) {
					const { scrollTop, scrollHeight, clientHeight } = messagesListRef?.current;
					const bottom = scrollHeight - scrollTop - clientHeight <= 100;
					if (bottom) {
						setTimeout(() => {
							// need this setimtime to render the DOM before scrolling to the bottom
							messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
						}, 0);
					} else {
						setScrollToBottomNewMessage(true);
					}
				}
			}
		}
	}, [newMessage]);

	// Hooks for loading previous messages
	useEffect(() => {
		if (!_.isEmpty(oldMessage)) {
			if (oldMessage.chatId === chatInFocus.id || oldMessage.chatId === chatInFocus._id) {
				setChatRoomItems(oldMessage.concat(chatRoomItems));
				setTimeout(() => {
					// TODO seperate out the method and call the static method to keep the code dry
					const targetToScrolTo = document?.getElementsByClassName(oldMessage[oldMessage.length - 1]?.id);
					if (targetToScrolTo.length > 0) {
						targetToScrolTo[0].scrollIntoView();
					}
				}, 100);
			}
		}
	}, [oldMessage]);

	// logic for popping up new message notification hanging on the screen for the user to click to scroll to the bottom
	const scrollToBottomTrigger = () => {
		setScrollToBottomNewMessage(false);
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const displayTimeSeparator = (chatRoomItems: ChatRoomItemsInterface[], index: number): boolean => {
		const chatListMessageCreatedDate = chatRoomItems.map((ele) => ele.createdAt);

		const checkTimeDifference =
			moment(chatListMessageCreatedDate[index - 1]).format('DD MM YY') !==
				moment(chatListMessageCreatedDate[index]).format('DD MM YY') || index === 0;

		return checkTimeDifference;
	};

	const renderTimeSeparator = (chatItem: ChatRoomItemsInterface) => {
		return (
			<FlexboxGrid.Item colspan={24} className="text-center my-5">
				{moment(chatItem.createdAt).format('DD MM YY') === moment().format('DD MM YY') ? (
					<span className="text-sm text-gray-500">{moment(chatItem.createdAt).format('[Today]')}</span>
				) : (
					<span className="text-sm text-gray-500">{moment(chatItem.createdAt).format('DD MMMM YYYY')}</span>
				)}
			</FlexboxGrid.Item>
		);
	};

	return (
		<>
			<FlexboxGrid.Item colspan={24}>
				<FlexboxGrid.Item
					as={Col}
					colspan={24}
					className="chat-room-height w-full bg-gray-50 overflow-y-auto"
					ref={messagesListRef}
				>
					<>
						{chatRoomItems.map((chatItem, index) => {
							{
								/* loop to get the chat messages and the details */
							}
							return (
								<FlexboxGrid
									ref={messagesEndRef}
									key={index}
									// style={{ backgroundColor: '#FAFAFA', display: 'block', height: '70px' }}
									className={`${chatItem.id}`}
								>
									<FlexboxGrid.Item colspan={24}>
										{displayTimeSeparator(chatRoomItems, index)
											? renderTimeSeparator(chatItem)
											: ''}
									</FlexboxGrid.Item>
									{/* message */}
									<FlexboxGrid.Item colspan={24} key={chatItem.id}>
										<ChatRoomMessage
											loggedInUserParticipantId={loggedInUserParticipantId}
											chatItem={chatItem}
											setChatRoomItems={setChatRoomItems}
											chatRoomItems={chatRoomItems}
											chatInFocus={chatInFocus}
										/>
									</FlexboxGrid.Item>
									{/* message time and read and unread receipts */}
									<FlexboxGrid.Item colspan={24}>
										<ChatRoomMessageDetails
											loggedInUserParticipantId={loggedInUserParticipantId}
											chatItem={chatItem}
											chatInFocus={chatInFocus}
										/>
									</FlexboxGrid.Item>
								</FlexboxGrid>
							);
						})}
					</>
				</FlexboxGrid.Item>
				{/* Logic for showing a new message has been recieved or not and whether to scroll down to view it */}
				<ScrollToBottom
					scrollToBottomNewMessage={scrollToBottomNewMessage}
					scrollToBottomTrigger={scrollToBottomTrigger}
				/>
				<FlexboxGrid.Item className="py-3 px-3 bg-white border-t-2 border-solid" as={Col} colspan={24}>
					<SendChatMessage
						updateSeenMessages={sendChatClicked}
						files={files}
						setFiles={setFiles}
						loggedInUserDetails={loggedInUserDetails}
					/>
				</FlexboxGrid.Item>
				{files.length > 0 && (
					<div
						className={
							getWindowSize(windowSize.width) === windowSizeType.XS
								? 'chat-room-height absolute z-10 w-full bg-gray-50'
								: 'chat-room-height absolute z-10 w-full bg-gray-50'
						}
					>
						<ChatRoomAttachmentFile files={files} setFiles={setFiles} />
					</div>
				)}
			</FlexboxGrid.Item>
		</>
	);
}
