import { createContext, useContext, FC, useEffect } from 'react';
import io, { Socket } from 'socket.io-client';
import Cookies from 'js-cookie';

const socket = io(`${process.env.REACT_APP_CHAT_API_BASE_URL}`, {
	transports: ['websocket'],
});

const SocketContext = createContext<Socket>(socket);

export const useSocket = (): Socket => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
interface Props {
	// any props that come into the component
}

export const SocketProvider: FC<Props> = ({ children }) => {
  useEffect(() => {
    // Emit INIT when the socket connects
    socket.on("connect", () => {
      socket.emit("INIT", { token: Cookies.get("token") });
    });

    // Cleanup function to prevent memory leaks
    return () => {
      socket.off("connect");
    };
  }, []);
	return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
